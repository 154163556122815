import React, { useState, useEffect, useRef, FormEvent } from 'react';
import './App.css';
import optuneLogo from './assets/white_logo.png';
import backgroundLogo from './assets/logo_background.png';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPromo, setShowPromo] = useState(false);
  const signupSectionRef = useRef<HTMLElement>(null);
  const valuePropositionRef = useRef<HTMLElement>(null);
  const [formData, setFormData] = useState({
    email: '',
    company: '',
    useCase: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    // Preload images for smooth experience
    const images = [optuneLogo, backgroundLogo];
    Promise.all(images.map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = reject;
      });
    }))
      .then(() => {
        setIsLoading(false);
        
        // Show promotional banner after a delay
        setTimeout(() => {
          setShowPromo(true);
        }, 1000); // Show after 1 second - showing it earlier since it's at the top
      })
      .catch(() => setIsLoading(false));

    // Prevent body scroll when modal is open
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  // Function to handle scroll to next section
  const scrollToNextSection = () => {
    if (valuePropositionRef.current) {
      valuePropositionRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  // Function to handle scroll to features from the second scroll indicator
  const scrollToFeatures = () => {
    if (signupSectionRef.current) {
      signupSectionRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  // Setup message listener to know when form is submitted successfully
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Ensure message is from Google Forms domain
      if (event.origin.includes('google.com')) {
        if (event.data === 'formSubmitted') {
          setFormSubmitted(true);
          setIsSubmitting(false);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleModalClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      // Reset form when closing modal
      if (!formSubmitted) {
        setFormData({
          email: '',
          company: '',
          useCase: ''
        });
      }
    }
  };

  const openWaitlistModal = () => {
    setIsModalOpen(true);
    setFormSubmitted(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id === 'email' ? 'email' : id === 'company' ? 'company' : 'useCase']: value
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Get the form element and submit it to google (it has the proper action and method)
    if (formRef.current) {
      formRef.current.submit();
      
      // Google Forms doesn't always send a message back, so we set a fallback timer
      // to ensure users don't get stuck with the spinner
      setTimeout(() => {
        setFormSubmitted(true);
        setIsSubmitting(false);
      }, 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="App">
      {/* Promotional banner at the top */}
      {showPromo && (
        <div className="promo-banner">
          <div className="promo-content">
            <span className="promo-text">🔥 Early Access Available! Join our waitlist today to be the first to experience Optune!</span>
            <button 
              className="promo-button"
              onClick={openWaitlistModal}
            >
              Join Waitlist
            </button>
          </div>
          <button 
            className="promo-close"
            onClick={() => setShowPromo(false)}
            aria-label="Close promotion"
          >
            ×
          </button>
        </div>
      )}

      <div className="background-container">
        <img src={backgroundLogo} className="background-logo" alt="background" loading="lazy" />
      </div>
      <main className="landing-container">
        <div className="content-wrapper">
          <img
            src={optuneLogo}
            className="main-logo"
            alt="Optune Logo"
            loading="eager"
          />

          {/* Models Advantages Section */}
          <section className="models-advantages">
            <h1 className="main-heading">
              Small-specific models are <strong>better</strong>, <strong>faster</strong>, <strong>cheaper</strong>, <strong>more secure</strong> and most importantly, <strong>in your control</strong>.
            </h1>
            <div 
              className="scroll-indicator" 
              onClick={scrollToNextSection}
              role="button"
              tabIndex={0}
              aria-label="Scroll to next section"
            >
              <div className="scroll-arrow"></div>
              <div className="scroll-text">Learn how Optune helps</div>
            </div>
          </section>

          {/* Optune Value Proposition */}
          <section className="value-proposition" ref={valuePropositionRef}>
            <div className="features-grid">
              <div className="feature-card">
                <div className="feature-icon power-icon"></div>
                <h3>Seamless Transition to 1st-Party Models</h3>
                <p>
                  We enable companies to harness the power of open source models with the development speed and ease of commercial LLMs
                </p>
              </div>
              <div className="feature-card">
                <div className="feature-icon platform-icon"></div>
                <h3>Task-Specific Optimization</h3>
                <p>
                  Our API and platform convert task-specific prompts into task-specific models for a seamless transition from 3rd-party to 1st-party models
                </p>
              </div>
            </div>
            
            {/* Call to Action */}
            <div className="cta-container">
              <p className="cta-text">Stay in the Loop. Be the First to Know.</p>
              <div className="cta-button-container">
                <div className="arrow-right">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M19 12H5M12 19l-7-7 7-7"/>
                  </svg>
                </div>
                <button className="cta-button" onClick={openWaitlistModal}>
                  Join the Waitlist
                </button>
                <div className="arrow-left">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </div>
              </div>
            </div>
          </section>

          <div 
            className="scroll-indicator"
            onClick={scrollToFeatures}
            role="button"
            tabIndex={0}
            aria-label="Scroll to next section"
          >
            <div className="scroll-arrow"></div>
          </div>

          <section ref={signupSectionRef} className="signup-section">
            <h2>Stay in the Loop. Be the First to Know.</h2>
            <p>Sign up below to receive exclusive updates and early access when we launch.</p>

            <button
              className="signup-button"
              onClick={openWaitlistModal}
              aria-label="Join the Waitlist"
            >
              <span className="button-text">
                Join the Waitlist
                <span className="button-icon" aria-hidden="true"></span>
              </span>
              <span className="button-shine"></span>
            </button>
          </section>

          <section className="contact-section">
            <a
              href="mailto:info@optune.ai"
              className="contact-link"
              aria-label="Contact us via email"
            >
              Contact us: info@optune.ai
            </a>
          </section>
        </div>
      </main>

      {/* Google Form Modal with a stunning design */}
      {isModalOpen && (
        <div
          className="modal-overlay"
          onClick={handleModalClose}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div
            className="modal-content"
            onClick={e => e.stopPropagation()}
          >
            <button
              className="modal-close"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close modal"
            >
              ×
            </button>
            <div className="modal-header">
              <h2 id="modal-title">Join the Waitlist</h2>
              <p>Be the first to experience Optune's revolutionary model technology</p>
            </div>
            <div className="form-container">
              {formSubmitted ? (
                <div className="success-message">
                  <div className="success-icon">✓</div>
                  <h3>Thank You for Joining!</h3>
                  <p>Thank you for joining the waitlist! We'll keep you in the loop about exciting updates and exclusive offers from optune.ai.</p>
                  <button 
                    className="close-button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              ) : (
                <>
                  {/* Hidden iframe to handle Google form submission */}
                  <iframe
                    ref={iframeRef}
                    name="hidden_iframe"
                    id="hidden_iframe"
                    style={{ display: 'none' }}
                    title="Google Form Submission Frame"
                    onLoad={() => {
                      // This will trigger when the iframe loads after form submission
                      // Only consider it a form submission if we're in submitting state
                      if (isSubmitting) {
                        setFormSubmitted(true);
                        setIsSubmitting(false);
                      }
                    }}
                  ></iframe>
                  
                  {/* Custom styled form that submits to Google Forms */}
                  <form 
                    ref={formRef}
                    className="custom-form" 
                    onSubmit={handleSubmit}
                    action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSddQVa7GOkkKATgWCaAz3CAA2OjVxC80UJjAL_LF-NihUtpZg/formResponse"
                    method="post"
                    target="hidden_iframe"
                  >
                    <div className="form-group">
                      <label htmlFor="email">What is your email?</label>
                      <input 
                        type="email" 
                        id="email" 
                        name="entry.744643131" // Updated Google form entry ID
                        placeholder="Your email" 
                        required 
                        className="form-input"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company">Company (optional)</label>
                      <input 
                        type="text" 
                        id="company" 
                        name="entry.412878903" // Updated Google form entry ID for company
                        placeholder="Your company name" 
                        className="form-input"
                        value={formData.company}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="use-case">What's your primary use case for small models? (optional)</label>
                      <textarea 
                        id="use-case" 
                        name="entry.2094245784" // Updated Google form entry ID for use case
                        placeholder="Tell us about your use case" 
                        className="form-textarea"
                        value={formData.useCase}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      ></textarea>
                    </div>
                    <button 
                      type="submit" 
                      className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="spinner"></span>
                      ) : (
                        <>
                          <span className="button-text">Submit</span>
                          <span className="button-icon">→</span>
                        </>
                      )}
                    </button>
                    <p className="form-footer">
                      We'll never share your information with third parties.
                    </p>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
